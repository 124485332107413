<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="$emit('modalShown');initData()"


    >
        <template #modal-title>
            {{$t('label_add_the_amount_due_to_the_law_firm')}}
        </template>
        <template #default="{ hide }">


        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_gross_payment')"
                        rules="required|price"
                        slim
                >
                    <b-form-group
                            :label="$t('label_gross_payment')"
                    >
                        <b-form-input
                                class="price-input"
                                required
                                v-model="itemData.gross_amount"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_gross_payment')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_date_of_payment')"
                            rules="required"
                            class="mr-1 flex-grow-1"
                            slim
                    >
                        <b-form-group :label="$t('label_date_of_payment')">

                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"
                                    :value="itemData.deadline_at"

                                    @input="itemData.deadline_at = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


            <validation-provider
                    class="mt-2"
                    #default="validationProps"
                    :name="$t('label_to_ocr')"
                    slim
            >
                <b-form-group
                        label=""
                        label-for="meeting_type_id"
                >
                    <!--:checked="itemData.google_sync"-->
                    <b-form-checkbox
                            id="due-law-ocr"
                            value="1"
                            unchecked-value="0"
                            v-model="itemData.ocr"
                    >
                        <label for="due-law-ocr">{{ $t('label_to_ocr') }}</label>
                    </b-form-checkbox>
                </b-form-group>
            </validation-provider>

            <validation-provider
                    class="mt-2"
                    :name="$t('label_document')"
                    slim
                    #default="validationProps"

            >

                <file-input

                        v-model="document"
                        class="file--uploader drop-block mb-3"
                        :max-files-count='10'
                        :required="true"
                        :multiple="false"
                        allowed-extensions='*'
                        :max-file-size='100'
                        :url="''"
                >
                    <template #uploadBody>
                        <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                        <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                        {{$t('label_add_file')}}
                    </template>

                    <template #errorBug="fileErrors">

                        <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </template>
                </file-input>


            </validation-provider>

            <b-progress class="progress-form-control" v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>

            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_edit'):$t('label_submit')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
         BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend, BProgress,
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'
    import {serialize} from 'object-to-formdata';
    import {AGREEMENT_PREFIX, DEAL_PREFIX, PAYMENT_PREFIX, DUE_LAW_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

             BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend, BProgress,

            ValidationProvider,
            ValidationObserver,
            fileInput
            // vSelect
        },
        props:['moduleItem','editedItem', 'isDeal'],
        data() {
            return {
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                PAYMENT_PREFIX,
                MODULE_PREFIX:'',
                PREFIX,

                action: 'adding',

                blankItemData: {
                    gross_amount:'',
                    ocr: 0,
                    deadline_at: null,
                },

                document:[],
                itemData:{},


            }
        },

        methods: {
            initData(){

                if(this.editedItem){
                    this.action = 'editing';

                    let item = Object.assign({},this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    item.deadline_at = formatDate(item.deadline_at, 'DD/MM/YYYY', 'unix');
                    item.gross_amount = item.gross_amount.formatPrice();
                    this.itemData = item ;
                } else {
                    this.action = 'adding';
                    this.itemData =  Object.assign({},this.blankItemData)
                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){

                    let formData = new FormData();
                    this.itemData.gross_amount = this.itemData.gross_amount.formatPriceToNumber();
                    formData = serialize(this.itemData, {}, formData);

                    if( this.action == 'adding') {
                        formData.append('agreement_id', this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id);
                    } else {

                        formData.append('agreement_id', this.editedItem.agreement_id);
                    }

                    if (this.document.length > 0) {
                        for(let i = 0; i< this.document.length; i++){
                            formData.append('document[]', this.document[i]);
                        }
                    }

                    if (this.action == 'adding') {
                        this.async('post', '/' + this.AGREEMENT_PREFIX + '_' + this.PAYMENT_PREFIX + 's/process_due_law_table', formData, function (resp) {
                            this.$emit('item-added', resp.data.item);
                            this.$bvModal.hide(this.PREFIX + '-modal');
                        }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                    } else {
                        this.async('put', '/' + this.AGREEMENT_PREFIX + '_' + this.PAYMENT_PREFIX+ 's/process_due_law_table/' + this.itemData.id, formData, function (resp) {
                            this.$emit('item-edited', {});
                            this.$bvModal.hide(this.PREFIX + '-modal');
                        }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                    }

            }
        },
        created(){
            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
        }


    }
</script>
