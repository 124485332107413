<template>
    <div :class="wrapClass">

        <label v-if="label" class="mb-5 component-title">{{label}}</label>

        <div>
            <div  class="draggable-row">
                <div class="draggable-input-wrap" v-cloak @drop.prevent="processImages($event)"
                     @dragleave.prevent="dragleaveInput($event)" @dragover.prevent="drogoverInput($event)">

                    <div class="btn upload-body" @click.prevent="openFileUploader()">
                       <div class="file-counter-wrap">
                           <slot name="uploadBody">Wgraj pliki</slot>
                           <div :id="'popover-'+inp_id" v-show="cloned_input_files.length > 0" class="files-counter badge badge-secondary">{{cloned_input_files.length}}</div>
                           <b-popover
                                   class="bg-info"
                                   :target="'popover-'+inp_id"
                                   triggers="hover"
                                   custom-class="file-listing"
                                   placement="top"
                                   variant="secondary"
                           >
                               <template #title>
                                   <span>{{$t('label_documents')}}</span>
                               </template>
                               <div class="tooltip-content">
                                   <div class="file-list-item" v-for="fl, ind  in cloned_input_files" :key="ind"><span class="badge badge-light-success">{{fl.name}}</span></div>
                               </div>
                           </b-popover>
                       </div>


                    </div>
                    <div  v-if="clearable  && cloned_input_files.length > 0" @click.prevent="updateInputFiles([]);" class="file-remover badge badge-danger">x</div>

                    <input :disabled="disabled" :name="name" :required="required" ref="file-uploader" class="hidden"
                           @change="processImages($event)" :multiple="multiple"
                           type="file">
                </div>

                <div class="added-img-wrap" v-for="image, ind in uploadedFiles" :key="'upl-'+image.id" :id="'dropbox-img-'+image.id">
                    <slot name="uploadedTemplate" :image="image" :ind="ind">
                        <a :href="url + '/' + image.path" :data-lightbox="image.display_name">
                            <img :alt="image.display_name" :src="url + '/' + image.path">
                        </a>

                        <button v-if="savedImagesDeleting" class="delete-img" @click.prevent="deleteFile(image.id)"><i
                                class="fa fa-trash" aria-hidden="true"></i></button>
                        <button v-if="savedImagesUploading" class="upload-img" @click.prevent="downloadFile(image.id)"><i
                                class="fa fa-download" aria-hidden="true"></i></button>
                    </slot>

                </div>

                <div v-if="thumbs">
                    <div class="added-img-wrap" v-for="file, ind in cloned_input_files">

                        <!--<img  v-if="file['file_type'] === '.pdf'" :src="url+'images/pdf.svg'" :alt="file.name">-->

                        <img :ref="'img-prev-'+ind" @click="showBase64ImagePreview(file)"
                             :src="file['base64']?file['base64']:$laravel.base_url+'images/loading.gif'"
                             :alt="file.name">
                        <button class="delete-img" @click.prevent="forgetFile(ind)"><i class="fa fa-trash"
                                                                                       aria-hidden="true"></i></button>
                    </div>
                </div>

            </div>

            <div  v-if="uploadFilesErrors.length > 0" class="draggable-error-block">
                <slot name="errorBug" :errors="uploadFilesErrors" ><div >{{uploadFilesErrors[0]}}</div></slot>
                <!--<slot name="errorBug" :errors="uploadFilesErrors" >Error</slot>-->
            </div>

        </div>

        <div v-if="previewing" class="preview-bg"></div>
        <div v-if="previewing" class="preview-img-wrap">

            <div class="closeImg" @click="closePreviewBase64()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <p class="navGal"></p>
            <div class="clearfix"></div>
            <div class="divWithImg">
                <img class="preview-img " src="" alt="">
            </div>


        </div>


    </div>
</template>


<script>
    import {BPopover} from 'bootstrap-vue'

    export default {
        components:{
            BPopover
        },
        props: {
            // files: {
            //     default: function () {
            //         return [];
            //     }, type: Array
            // },
            uploadedFiles: {
                default: function () {
                    return [];
                }, type: Array
            },
            // showTempFileInfo:{default: true, type: Boolean},
            wrapClass: {default: '', type: String}, //file--uploader.size--big.drop-block
            required: {default: false, type: Boolean},
            clearable: {default: true, type: Boolean},
            multiple: {default: false, type: Boolean},
            disabled: {default: false, type: Boolean},
            name: {default: 'files', type: String},
            thumbs: {default: false, type: Boolean},
            allowedExtensions: {default: false, validator: (v) => {return  v === '*' || v === false ||  typeof v === 'object'  }}, //[".jpg", ".jpeg", ".png"]
            // savedImagesDeleting: {default: false, type: Boolean},
            // savedImagesUploading: {default: false, type: Boolean},
            maxFileSize: {default: 2}, //Mb
            maxFilesCount: {default: 1, type: Number},
            // url: {default: '/', type: String},
            label: {default: '', type: String},
            uploadingImages: {
                default: function () {
                    return [];
                }, type: Array
            },
            previewing: {default: false, type: Boolean},
            inputFiles: {
                default: function () {
                    return [];
                }, type: Array
            },
        },

        data() {
            return {
                inp_id:Date.now(),
                uploadFilesErrors: [],
                cloned_input_files: JSON.parse(JSON.stringify(this.inputFiles))
            }
        },
        model: {
            prop: 'inputFiles',
            event: 'files-updated'
        },
        watch:{
            inputFiles(newVal){
                let files = [];
                for(let i = 0; i < newVal.length; i++) {
                    let clonedFile = new File([newVal[i]], newVal[i].name);
                    clonedFile.base64 = newVal[i].base64;
                    files.push(clonedFile);
                }
                this.cloned_input_files = files;
            }
        },

        methods: {
            clear(){
                this.updateInputFiles([]);
            },
            openFileUploader() {
                this.$refs['file-uploader'].click();
            },
            forgetFile(ind) {
                if (this.cloned_input_files[ind] !== 'undefined') {
                    this.cloned_input_files.splice(ind, 1);
                    this.updateInputFiles(this.cloned_input_files);


                    // if(this.required && this.input_files.length < 0) {
                    //     this.uploadFilesErrors.push("The file is required");
                    // }
                }
            },
            deleteFile(id) {
                this.$emit('deletesavedfile', id);
            },
            fileError(e) {
                console.log('fileError', e);
            },
            drogoverInput(e) {
                let wrap = e.target.closest('.draggable-input-wrap');
                wrap.style['border-color'] = 'red';
                wrap.style['border-width'] = '2px';
            },
            dragleaveInput(e) {
                let wrap = e.target.closest('.draggable-input-wrap');
                wrap.style['border-color'] = '#BDBDBD';
                wrap.style['border-width'] = '1px';
            },
            downloadFile(id) {
                let win = window.open(this.$base_url + 'admin/orders/upload-file/' + id, '_blank');
                win.focus();
            },
            processImages(e) {
                let th = this;
                let newFiles = [];
                this.uploadFilesErrors = [];
                let _validFileExtensions = this.allowedExtensions;


                if (e.type && e.type === 'drop') {
                    let wrap = e.target.closest('.draggable-input-wrap');
                    wrap.style['border-color'] = '#BDBDBD';
                    wrap.style['border-width'] = '1px';
                    newFiles = Array.from(e.dataTransfer.files);
                } else if (e.type && e.type === 'change') {
                    newFiles = Array.from(e.target.files);
                }

                let allFiles = this.cloned_input_files.concat(newFiles);
                let filesCount = allFiles.length;

                if (filesCount > this.maxFilesCount) {
                    allFiles = allFiles.slice(filesCount - this.maxFilesCount);
                    filesCount = this.maxFilesCount;
                }

                let readers = [];
                let j = 0;

                // let clonedFiles = allFiles.map(a => Object.assign({}, a));
                files_loop:
                    for (let i = 0; i < filesCount; i++) {
                        let file = allFiles[i];
                        let fileName = allFiles[i].name;

                        if (file.size > 1024 * 1024 * parseFloat(this.maxFileSize)) {
                            this.uploadFilesErrors.push("The " + fileName + " file must not be larger than "+parseFloat(this.maxFileSize).toFixed(2)+" MB");
                            filesCount--;
                            allFiles.splice(i, 1);
                            i--;
                            continue files_loop;
                        }


                        let validExt = false;
                        let sCurExtension = '';

                        if(typeof _validFileExtensions == 'object' && _validFileExtensions.length > 0) {
                            extension_loop:
                                for (let y = 0; y < _validFileExtensions.length; y++) {
                                    sCurExtension = _validFileExtensions[y].toLowerCase();
                                    allFiles[i]['file_type'] = sCurExtension;
                                    if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension) {
                                        validExt = true;

                                        break extension_loop;
                                    }
                                }

                            if (!validExt) {
                                this.uploadFilesErrors.push("(" + fileName + ") " + this.$t('invalid_extension'));
                                // allowed extensions are: " + _validFileExtensions.join(", "));
                                filesCount--;
                                allFiles.splice(i, 1);
                                i--;
                                continue files_loop;
                            }
                        }
                        let isCopy = false;
                        copy_loop:
                            for (let z = 0; z < filesCount; z++) {
                                if (i === z) continue;
                                if (allFiles[z].size == allFiles[i].size && allFiles[z].name == allFiles[i].name) {
                                    isCopy = true;
                                    break copy_loop;
                                }
                            }

                        if (isCopy) {
                            this.uploadFilesErrors.push("You have already added " + fileName + " file");
                            filesCount--;
                            allFiles.splice(i, 1);
                            i--;

                            continue files_loop;
                        }

                        readers[i] = new FileReader();

                        readers[i].readAsDataURL(file);

                        readers[i].addEventListener("loadend", function (result) {
                            // console.log('loaded');
                            // if (file['file_type'] == '.pdf') {
                            // if (!allFiles[i]['base64']) {
                            //     allFiles[i]['base64'] = false;
                            //     axios.post(th.$laravel.base_url + 'get-pdf-preview', {base64: result.target.result}).then(function (response) {
                            //         // console.log(response);
                            //         allFiles[i]['base64'] = response.data.base64;
                            //         th.$refs['img-prev-' + i].setAttribute('src', response.data.base64);
                            //         // Vue.set(th.fileTransfer, 'files',  allFiles);
                            //     });
                            // }

                            // } else {
                            allFiles[i]['base64'] = result.target.result;
                            // }
                            j++;
                        }, false);

                        // this.$refs['mainImgErr'].reset();

                    }
                // if (th.required && filesCount == 0) {
                //     th.uploadFilesErrors.push("File is required");
                //     return;
                // }
                let timerId = setInterval(function () {
                    if (filesCount == j) {

                        th.updateInputFiles(allFiles);

                        clearInterval(timerId);
                    }
                }, 150);

            },
            showBase64ImagePreview(file) {

                $('.preview-bg').show(function () {
                    $('.preview-img-wrap').css({'display': 'block'}).find('img').attr('src', file['base64']);

                    $('.navGal').text(file['name']);

                    $('body').css('overflow', 'hidden');
                });

            },
            closePreviewBase64() {

                $('.preview-img-wrap').fadeOut(500, function () {
                    $('.preview-bg').fadeOut();

                    $('body').css('overflow', 'auto');
                });
            },
            updateInputFiles(val){
                this.cloned_input_files = val;

                let dt = new DataTransfer();
                this.cloned_input_files.forEach((file) => {
                    dt.items.add(file)
                });
                let fileList = dt.files;

                this.$refs['file-uploader'].files = fileList;

                this.$emit('files-updated', this.cloned_input_files);
            }
        },

    }
</script>

